import * as React from "react"
import { Link } from "gatsby"
import Seo from "components/Seo";

import { Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

import { useSiteMetadata } from "common/hooks";

const BoldTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  display: 'inline-block'
}));

const NotFoundPage = () => {
  const { siteUrl } = useSiteMetadata()
  return (
    <>
      <Seo title={`404 `}/>
      <Typography variant="h5" component="h1" sx={{mb: 2}} color="primary">
        404
      </Typography>
      <BoldTypography>
        Pagina cautata nu exista.
      </BoldTypography>
      <Typography>
        Ne cerem scuze, dar nu am putut găsi pagina pe care o cautati.
      </Typography>
      <Typography>
        Click aici pentru a merge in prima pagina <Link to={siteUrl}>{siteUrl}</Link>.
      </Typography>
    </>
  )
}

export default NotFoundPage
